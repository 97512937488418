@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: Trebuchet-Italic;
  src: url("/src/fonts/trebucit.ttf") format("truetype");
}

@font-face {
  font-family: Trebuchet;
  src: url("/src/fonts/trebuc.ttf") format("truetype");
}

@font-face {
  font-family: Trebuchet-Bold;
  src: url("/src/fonts/trebucbd.ttf") format("truetype");
}

@font-face {
  font-family: Trebuchet-Bold-Italic;
  src: url("/src/fonts/trebucbi.ttf") format("truetype");
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050505;
}

.hero-text-shadow {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
}

.body-box-shadow {
  -moz-box-shadow: 0px 0px 40px 30px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 0px 40px 30px rgba(0, 0, 0, 0.46);
  box-shadow: 0px 0px 40px 30px rgba(0, 0, 0, 0.46);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s;
}
