@keyframes changewidth {
  from {
    transform: scalex(1);
    filter: saturate(0%);
    filter: brightness(100%);
    filter: contrast(100%);
    opacity: 0.3;
  }

  to {
    transform: scalex(1.5);
    filter: saturate(130%);
    filter: brightness(130%);
    filter: contrast(100%);
    opacity: 0.7;
  }
}

.change-width {
  animation-delay: 3s;
  animation-duration: 3s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
